
.com-introduction-page {
    .com-introduction {
        display: flex;
        justify-content: space-between;
        padding: 27px 30px;
        min-height: 500px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .left-com-introduction {
            width: 320px;
            h5 {
                width: 100%;
                height: 58px;
                line-height: 58px;
                background: #cb2f3c;
                color: #fff;
                font-size: 20px;
                padding: 0 24px;
                box-sizing: border-box;
                img {
                    width: 29px;
                    height: 15px;
                    vertical-align: middle;
                }
            }
            ul {
                li {
                    width: 100%;
                    height: 62px;
                    line-height: 62px;
                    border: 1px solid #e6e6e6;
                    border-top: none;
                    font-size: 18px;
                    color: #666;
                    padding: 0 24px;
                    box-sizing: border-box;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    a {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .active {
                    background: #f7f7f7;
                    color: #bd1a2d;
                }
            }
        }
        .right-com-introduction {
            .content-intro {
                width: 796px;
            }
        }
    }
    .showPdf {
        position: relative;
        border: 2px solid #3c3c3c;
        background: #3c3c3c;
        box-shadow: -6px 4px 8px #ccc;
    }
    .showPdf .down {
        position: absolute;
        top: 9px;
        right: 130px;
    }
    .showPdf img {
        width: 15px;
    }
}
